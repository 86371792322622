import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';
import { slugify } from '../utils/Misc';
const faqs = [
  {
    title: 'What is an Event Organiser? what they do? ',
    content:
      'An organiser will host a tournament, they will have a system to control and manage the tournament, they can hire streamers, on this panel E-Warz will also have a sponsor section as well, which can show interest in any tournament, this if both parties agree sponsors can financially support any tournament.',
  },
  {
    title: 'How do I find or join a tournament?',
    content:
      'The players or team leader or manager can create the team in his platform, they can look for any tournament where the team can take part in, once they have signed up for the tournament, the organisers can schedule the matches and the information will be passed to the member who will be part of that match.',
  },
  {
    title: 'Who is a Team Manger?',
    content:
      'A team manager/ Team leader will have a overview on all tournaments they have took part in, they will see how they are doing as team, what are the future tournaments they can take party in, also by seeming the recent matches they can make important discussions on the team and the are which needs improvement.',
  },
  {
    title: 'What is a Commentator and what do they do?',
    content:
      'A commentator will be the online state host in other words this person will be sports caster who provides commentary during a live tournament event, commentator will usually work either the streamer in synchronisation to deliver the best experience to the audience.',
  },
];
const Faq = () => {
  return (
    <Layout>
      <Helmet title="Frequently asked questions" />
      <section className="clean-block features bg-dark-alt text-white pb-lg-5">
        <div className="container">
          <div className="block-heading">
            <h2 className="text-info">Frequently Asked Questions</h2>

            {/* <p>
              following are the 
            </p> */}
          </div>

          {/* <div className="row justify-content-center">
            <div className="col-md-5 feature-box">
              <i className="far fa-star icon"></i>
              <h4>Bootstrap 4</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim
                nec auctor in, mattis vitae leo.
              </p>
            </div>
            <div className="col-md-5 feature-box">
              <i className="far fa-edit icon"></i>
              <h4>Customizable</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim
                nec auctor in, mattis vitae leo.
              </p>
            </div>
            <div className="col-md-5 feature-box">
              <i className="fas fa-mobile icon"></i>
              <h4>Responsive</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim
                nec auctor in, mattis vitae leo.
              </p>
            </div>
            <div className="col-md-5 feature-box">
              <i className="fas fa-recycle icon"></i>
              <h4>All Browser Compatibility</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim
                nec auctor in, mattis vitae leo.
              </p>
            </div>
          </div> */}

          <div class="accordion" id="accordionExample">
            {faqs.map(({ title, content }, idx) => (
              <div class="card bg-dark" key={idx}>
                {console.log(slugify(title))}
                <div class="card-header" id="title">
                  <h2 class="mb-0">
                    <button
                      class="btn text-white btn-block text-left"
                      type="button"
                      data-toggle="collapse"
                      data-target={`#${slugify(title)}`}
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      {title}
                    </button>
                  </h2>
                </div>

                <div
                  id={slugify(title)}
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">{content}</div>
                </div>
                {console.log(faqs.length, idx)}
                {faqs.length !== idx + 1 && <div class="dropdown-divider border-primary"></div>}
              </div>
            ))}
          </div>
          <div className="text-center mt-4">
            <p className="text-primary">More content will be added soon.</p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Faq;
